import { $isMarkNode, $unwrapMarkNode, $wrapSelectionInMarkNode, MarkNode } from '@lexical/mark';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNodeByKey, $getSelection, $isRangeSelection, COMMAND_PRIORITY_EDITOR, createCommand, LexicalEditor } from 'lexical';
import { useEffect } from 'react';
import { IMap } from 'typings/DeepIMap';
import { $getAbbreviationId } from './utils';

export const ABBREVIATION_ADD = createCommand<string | undefined>();

export type LexicalAbbreviationValues = {
  abbreviationId: string | undefined;
  abbreviationTerm: string | undefined;
};

type Props = {
  onChange: (values: LexicalAbbreviationValues) => void;
};

export function AbbreviationPlugin(props: Props): null {
  const { onChange } = props;

  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      ABBREVIATION_ADD,
      (id) => {

        const selection = $getSelection();
        if (!selection || !$isRangeSelection(selection)) {
          return true;
        }

        if (!id) {
          const node = selection.getNodes()[0];
          const keyNode = node.__parent;

          if (keyNode) {
            editor.update(() => {
              const parentNode: null | MarkNode = $getNodeByKey(keyNode);
              if ($isMarkNode(parentNode)) {
                const [abbrId] = parentNode.__ids;
                parentNode.deleteID(abbrId);
                if (parentNode.getIDs().length === 0) {
                  $unwrapMarkNode(parentNode);
                }
              }
            });
          }

          return true;
        }

        editor.update(() => {
          if ($isRangeSelection(selection)) {
            const isBackward = selection.isBackward();

            // Wrap content in a MarkNode
            $wrapSelectionInMarkNode(selection, isBackward, id);
          }
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  useEffect(() => {
    const readEditorStateAndNotifyChange = (): void => onChange({
      abbreviationId: $getAbbreviationId(editor as unknown as IMap<LexicalEditor>),
      abbreviationTerm: $getSelection()?.getTextContent() || '',
    });
    editor.getEditorState().read(readEditorStateAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(readEditorStateAndNotifyChange);
    });
  }, [editor, onChange]);

  return null;
}
