import { LinkNode } from '@lexical/link';
import { MarkNode } from '@lexical/mark';
import { $getNodeByKey, $getSelection, $isRangeSelection, LexicalNode } from 'lexical';
import * as Constants from 'const';
import * as inlineStylesUtils from 'utils/inlineStyles';
import { FontPluginStyle } from '../Plugins/FontPlugin/style';
import { ConvertStylesEnum, LexicalFormatCodeEnum } from './types';

export function getFormateCode(styles: string[]): number {
  return styles.reduce((acc, curr) => {
    const isUnderline = curr.includes(ConvertStylesEnum.UNDERLINE) ? LexicalFormatCodeEnum.UNDERLINE : LexicalFormatCodeEnum.DEFAULT;
    const isSub = curr.includes(ConvertStylesEnum.SUB) ? LexicalFormatCodeEnum.SUB : LexicalFormatCodeEnum.DEFAULT;
    const isSup = curr.includes(ConvertStylesEnum.SUP) ? LexicalFormatCodeEnum.SUP : LexicalFormatCodeEnum.DEFAULT;

    return acc + isUnderline + isSup + isSub;
  }, 0);
}

export function getStyleArray(styles: string[]): string[] {
  const result: string[] = [];

  const colorHEX = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_COLOR);
  if (colorHEX) {
    result.push(`${FontPluginStyle.COLOR_HEX}: ${colorHEX}`);
    const name = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_COLOR_NAME);
    if (name) {
      result.push(`${FontPluginStyle.COLOR_NAME}: ${name}`);
    }
    const tint = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_COLOR_TINT);
    if (tint) {
      result.push(`${FontPluginStyle.COLOR_TINT}: ${tint}`);
    }
  }

  const fontFamily = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_FAMILY);
  if (fontFamily) {
    result.push(`${FontPluginStyle.FONT_FAMILY}: ${fontFamily}`);
    const characterStyleName = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.CHARACTER_STYLE_NAME);
    if (characterStyleName) {
      result.push(`${FontPluginStyle.CHARACTER_STYLE_NAME}: ${characterStyleName}`);
    }
  }

  const fontSize = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_SIZE);
  if (fontSize) {
    result.push(`${FontPluginStyle.FONT_SIZE}: ${fontSize}`);
  }

  const fontStyle = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_STYLE);
  if (fontStyle) {
    result.push(`${FontPluginStyle.FONT_STYLE}: ${fontStyle}`);
  }

  const fontWeight = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_WEIGHT);
  if (fontWeight) {
    result.push(`${FontPluginStyle.FONT_WEIGHT}: ${fontWeight}`);
  }

  // IN-PROGRESS: the next values should also be processed correctly

  // BULLET_COLOR = '_bullet_color_hex',
  // BULLET_COLOR_LEGACY = '_bullet_color_',
  // BULLET_COLOR_NAME = '_bullet_color_name_',
  // BULLET_COLOR_TINT = '_bullet_color_tint_',

  return result;
}

export type TLexicalNode = {
  key: string;
  type: string;
  value: LexicalNode & { __text?: string; __ids?: string[] };
};

export const $getParentNode = (): LinkNode | MarkNode | null => {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    return null;
  }

  const parentKey = selection.getNodes()[0].__parent ?? '';

  return $getNodeByKey(parentKey);
};
