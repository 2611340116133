import { RawDraftEntityRange, RawDraftInlineStyleRange } from 'draft-js';

export type TCombination = {
  position: number[];
  style: string[];
  type: string;
};

export type TSlicedText = {
  text: string;
  styles: string[];
  type?: string;
  data?: { url?: string; id?: string };
};

export enum ConvertStylesEnum {
  UNDERLINE = 'UNDERLINE',
  SUB = 'SUBSCRIPT',
  SUP = 'SUPERSCRIPT',
}

export enum LexicalFormatCodeEnum {
  BOLD = 1,
  ITALIC = 2,
  UNDERLINE = 8,
  DEFAULT = 0,
  SUB = 32,
  SUP = 64,
}

export type TRanges = RawDraftEntityRange & RawDraftInlineStyleRange;

export enum LexicalFieldDescribes {
  MARK = 'mark',
  LINK = 'link',
  BLANK = '_blank',
  LTR = 'ltr',
  PHARAGRAPH = 'paragraph',
}

export type TEditStateRoot = {
  direction: string;
  format: string;
  indent: number;
  type: string;
  version: number;
};

export type TEditorState = {
  root: TEditStateRoot;
  lastSaved?: number;
  source?: string;
  version?: string;
};

export type TEditorStateImport = {
  editorState: TEditorState;
};
